import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import StyledPanel from "../StyledPanel";
import clsx from "clsx";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import SearchBar from "../../SearchBar";
import AccessGroupService from "../../../services/AccessGroupService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import AccessGroupForm from "../../Forms/AccessGroup";
import AccessGroupList from "../../AccessGroups/List";
import useHasPermissions from "../../../hooks/useHasPermissions";
import _ from "lodash";
import AccessGroupFilters from "../../../components/Filters/AccessGroupFilters";

const accessGroupService = new AccessGroupService(apiClient);

const renderModes = ["assign", "manage"];

const AccessGroups = ({ className, accessHolderID, mode, disabled }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [newData, setNewData] = useState(false);
  const [formState, setFormState] = useState({
    open: false
  });
  const [accessGroupDialog, setAccessGroupDialog] = useState({
    open: false
  });
  const [accessGroupModalSearchTerm, setAccessGroupModalSearchTerm] = useState(
    ""
  );

  const [accessGroupTypeFilter, setAccessGroupTypeFilter] = useState("");

  const { hasPermissions } = useHasPermissions();
  const accessGroupsAdd = hasPermissions(["accessgroups.add"]);
  const AccessGroupAssignPermission = hasPermissions([
    _.isUndefined(accessHolderID) ? "accessholders.add" : "accessholders.edit"
  ]);

  const handleAssignClicked = () => {
    setAccessGroupDialog({ open: true });
  };

  const handleAddAccessGroup = () => {
    setFormState({ open: true });
  };

  const handleAccessAssign = async accessGroup => {
    try {
      await accessGroupService.AssignAccessHolderToGroup(
        accessGroup.accessGroupID,
        accessHolderID
      );
    } catch {
      enqueueSnackbar("Failed to assign access group to access holder", {
        variant: "error",
        tag: "groupAssignError"
      });
      return;
    }
    setNewData(prev => !prev);
    enqueueSnackbar("Successfully assigned", {
      variant: "success"
    });

    setAccessGroupDialog({ open: false });
  };

  const handleAccessGroupListDialogClose = () => {
    setAccessGroupDialog({ open: false });
    setAccessGroupModalSearchTerm("");
  };

  const handleAccessGroupSearchChange = val => {
    setAccessGroupModalSearchTerm(val);
  };

  const handleEditSubmit = () => {
    setNewData(prev => !prev);
    setFormState({ open: false });
  };
  
  const handleGroupTypeFilterChange = (val) => {
    setAccessGroupTypeFilter(val);
  };

  const handleFilterClear = () => {
    setAccessGroupTypeFilter("");
  };

  return (
    <>
      <StyledPanel
        className={clsx("accessgroup-panel", classes.root, className)}
        headerContent={
          <div className={clsx("header", classes.header)}>
            <Typography className={clsx("access-groups", classes.title)}>
              Access Groups
            </Typography>
            <div className={clsx("button-group", classes.btnGroup)}>
              {!_.isUndefined(accessHolderID) &&
                AccessGroupAssignPermission &&
                mode === "assign" && (
                  <Button
                    className={clsx("btn-accessgroup-assign")}
                    data-testid="accessgroup-assign-test"
                    variant="contained"
                    color="primary"
                    onClick={handleAssignClicked}
                    disabled={disabled}
                  >
                    Assign
                  </Button>
                )}
              {accessGroupsAdd && (
                <Button
                  className={clsx("btn-accessgroup-add")}
                  data-testid="accessgroup-add-test"
                  variant="contained"
                  color="primary"
                  onClick={handleAddAccessGroup}
                  disabled={disabled}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        }
        cardContent={
          <AccessGroupList
            className={clsx("accessgroup-list", classes.accessHolderList)}
            accessHolderID={accessHolderID}
            mode={AccessGroupAssignPermission ? "assign" : "disabled"}
            newData={newData}
          />
        }
      />
      <Dialog
        className={clsx("accessgroup-dialog")}
        fullWidth
        maxWidth="md"
        open={formState.open}
      >
        <DialogContent className={clsx("accessgroup-dialog-content")}>
          <AccessGroupForm
            className={clsx("accessgroup-form")}
            onCancel={() => setFormState({ open: false })}
            onSubmit={handleEditSubmit}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        className={clsx("assign-search-dialog")}
        fullWidth
        maxWidth="md"
        open={accessGroupDialog.open}
        onClose={handleAccessGroupListDialogClose}
      >
        <DialogContent className={clsx("assign-search-dialog-content")}>
          <SearchBar
            className={clsx("accessgroup-assign-search")}
            onChange={handleAccessGroupSearchChange}
            label="Access Groups"
            placeholder="Search by Name"
          />
          <AccessGroupFilters
            groupTypeFilter={accessGroupTypeFilter}
            onGroupTypeChange={handleGroupTypeFilterChange}
            onFilterClear={handleFilterClear}
          />
          <AccessGroupList
            role="dialog-access-group-list"
            className={clsx(
              "dialog-accessgroup-list",
              classes.accessHolderList
            )}
            accessHolderID={accessHolderID}
            mode="manage"
            selectable
            onSelect={handleAccessAssign}
            searchTerm={accessGroupModalSearchTerm}
            newData={newData}
            groupTypeFilter={accessGroupTypeFilter}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

AccessGroups.defaultProps = {
  mode: "manage"
};

AccessGroups.propTypes = {
  className: PropTypes.string,
  accessHolderID: PropTypes.string,
  mode: PropTypes.oneOf(renderModes)
};

export default AccessGroups;
